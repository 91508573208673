<template>
    <div class="services_con2" id="servicesID">
        <div :class="'services2 dirr ' + $store.state.direction">
            <div class="title d-flex d-sm-none">
                <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                <p class="tl">{{ $t("zaer_services") }}</p>
            </div>
            <div class="serBtns d-none d-sm-flex">
                <v-layout row wrap justify-center>
                    <v-btn-toggle v-model="toggle_serForm" mandatory>
                        <v-flex sm3 xs6 md12 lg12>
                            <v-btn outlined min-height="164" value="zeara_insted" class="btn"
                                @click="desvideoplayer('zeara','zeara_insted')">
                                <div class="img zeara_insted"></div>
                                <p class="tl">{{ $t("index_zyara") }}</p>
                            </v-btn>
                        </v-flex>
                        <v-flex sm3 xs6 md12 lg12>
                            <v-btn outlined min-height="164" value="live" class="btn d-none"
                                @click="inivideoplayer('lives')" ref="abc">
                                <div class="img live"></div>
                                <p class="tl">{{ $t("index_live") }}</p>
                            </v-btn>
                            <v-btn outlined min-height="164" value="masssage" class="btn"
                                @click="desvideoplayer('masssages','masssage')">
                                <div class="img message"></div>
                                <p class="tl">{{ $t("msg_message_to_shrine") }}</p>
                            </v-btn>
                        </v-flex>
                        <v-flex sm3 xs6 md12 lg12>
                            <v-btn outlined min-height="164" value="question" class="btn"
                                @click="desvideoplayer('questions','question')">
                                <div class="img question"></div>
                                <p class="tl">{{ $t("relig_questions") }}</p>
                            </v-btn>
                        </v-flex>
                        <v-flex sm3 xs6 md12 lg12 v-if="$i18n.locale=='ar'">
                            <v-btn outlined min-height="164" value="lost" class="btn" @click="iniLostCon('lost')">
                                <div class="img lost"></div>
                                <p class="tl">{{ $t("index_lost") }}</p>
                            </v-btn>
                        </v-flex>
                        <!-- <v-flex sm3 xs6 md12 lg12>
                            <v-btn outlined min-height="164" value="restaurant" class="btn"
                                @click="desvideoplayer('restaurants','restaurant')">
                                <div class="img zeara_insted"></div>
                                <p class="tl">التبرك بالمضيف</p>
                            </v-btn>
                        </v-flex> -->
                    </v-btn-toggle>
                </v-layout>
            </div>
            <div :class="'serCon '+serConStyle">
                <div class="serForm">
                    <div v-if="toggle_serForm=='zeara_insted'" class="zeara_con">
                        <a :href="'https://alkafeel.net/zyara?lang=' + $i18n.locale" class="title">
                            <!-- <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span> -->
                            <span><img src="/assets/img/z-mohammed_dark.svg" /></span>
                            <p class="tl" style="color:#FFF">{{ $t("index_zyara") }}</p>
                        </a>
                        <p class="tl"  style="color:#FFF">{{ zyarares.title }}</p>
                        <div>
                            <v-form class="contactForm" ref="form" v-model="valid">
                                <v-text-field @keyup.enter.native="zeara_send()" v-model="zeara_i.ab"
                                    background-color="rgba(255, 255, 255, 0.7)" :rules="[rules.required]"
                                    :placeholder="$t('zyara_ab')" outlined></v-text-field>
                                <!-- <v-text-field @keyup.enter.native="zeara_send()" v-model="zeara_i.name" background-color="rgba(255, 255, 255, 0.4)" :rules="[rules.required]" :placeholder="$t('name')" outlined></v-text-field> -->
                                <v-text-field @keyup.enter.native="zeara_send()" v-model="zeara_i.email"
                                    background-color="rgba(255, 255, 255, 0.7)" :rules="[rules.email]"
                                    :placeholder="$t('msg_email_optional')" outlined></v-text-field>

                                <v-btn style="align-self: center;" min-width="200" height="56" class="ma-2 tl"
                                    :loading="loading" color="rgba(177, 189, 82, 1)" @click="zeara_send()">
                                    <p class="alistSub tl">{{ $t('action_send') }}</p>
                                </v-btn>
                            </v-form>
                        </div>
                    </div>
                    <div v-if="toggle_serForm=='question'" class="question_con">
                        <a href="https://alkafeel.net/religious/index.php?add_quest" class="title">
                            <!-- <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span> -->
                            <span><img src="/assets/img/z-mohammed_dark.svg" /></span>
                            <p class="tl"  style="color:#FFF">{{ $t("relig_questions") }}</p>
                        </a>
                        <div>
                            <v-form class="questionForm" ref="form" v-model="valid">
                                <v-text-field @keyup.enter.native="question_send()" v-model="questions_i.name"
                                    background-color="rgba(255, 255, 255, 0.7)" :rules="[rules.required]"
                                    :placeholder="$t('name')" outlined></v-text-field>
                                <v-text-field @keyup.enter.native="question_send()" v-model="questions_i.phone"
                                    background-color="rgba(255, 255, 255, 0.7)" :placeholder="$t('Phone_Num_optional')"
                                    outlined></v-text-field>
                                <v-text-field @keyup.enter.native="question_send()" v-model="questions_i.email"
                                    background-color="rgba(255, 255, 255, 0.7)" :rules="[rules.required,rules.email]"
                                    :placeholder="$t('email')" outlined></v-text-field>
                                <v-textarea v-model="questions_i.quest" background-color="rgba(255, 255, 255, 0.7)"
                                    :rules="[rules.required]" :placeholder="$t('question_text')" outlined></v-textarea>
                                <v-btn style="align-self: center;" min-width="200" height="56" class="ma-2 tl"
                                    :loading="loading" color="rgba(177, 189, 82, 1)" @click="question_send()">
                                    <p class="alistSub tl">{{ $t('action_send') }}</p>
                                </v-btn>
                            </v-form>
                        </div>
                    </div>
                    <!-- <div v-if="toggle_serForm=='live'" class="live_con">
                        <a :href="'https://alkafeel.net/live?lang=' + $i18n.locale" class="title">
                            <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                            <p class="tl">{{ $t("abas_shrine_live") }}</p>
                        </a>
                        <div class="playerCON">
                            <div class="vidCon">
                                <video ref="videoPlayer" class="video-js"
                                    poster="https://alkafeel.net/alkafeel_back_test/img/misc/poster_1.png"></video>
                            </div>
                            <div class="vidSrc">
                                <div class="d-none d-sm-flex">
                                    <v-btn-toggle v-model="toggle_src">
                                        <v-btn outlined class="src" :id="'element'+(i+1)" :value="'src'+(i+1)"
                                            v-for="(x, i) in liveres" :key="x" @click="changeVIDsrc(x.src,x.pic)">
                                            <div :class="'icon'+(i+1)"></div>
                                            <div :style="'background-image:url(https://alkafeel.net/live/images/resize/'+x.pic+');'"
                                                :class="'img src'+(i+1)"></div>
                                            <p class="tl">{{x.title}}</p>
                                        </v-btn>
                                    </v-btn-toggle>
                                </div>
                                <div class="slid_con d-flex d-sm-none">
                                    <div class="slider dirr">
                                        <v-btn-toggle v-model="toggle_src">
                                            <swiper class="swiper dirr" ref="mySwiper3" :options="swiperOptions3">
                                                <swiper-slide v-for="(x, i) in liveres" :key="x">
                                                    <v-btn outlined class="src" :value="'src'+(i+1)"
                                                        @click="changeVIDsrc(x.src,x.pic)">
                                                        <div :class="'icon'+(i+1)"></div>
                                                        <div :style="'background-image:url(https://alkafeel.net/live/images/resize/'+x.pic+');'"
                                                            :class="'img src'+(i+1)"></div>
                                                        <p class="tl">{{x.title}}</p>
                                                    </v-btn>
                                                </swiper-slide>
                                            </swiper>
                                        </v-btn-toggle>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div v-if="toggle_serForm=='lost' && $i18n.locale=='ar'" class="lost_con">
                        <a :href="'https://alkafeel.net/lost?lang=' + $i18n.locale" class="title">
                            <!-- <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span> -->
                            <span><img src="/assets/img/z-mohammed_dark.svg" /></span>
                            <p class="tl" style="color:#FFF">{{ $t("index_lost") }}</p>
                        </a>
                        <div class="lostForm_conn">
                            <v-form :class="'lostForm ' + $store.state.direction" ref="form" v-model="valid">
                                <v-text-field @input="pre_lost_send()" @keyup.enter.native="lost_send()"
                                    onkeypress="return event.keyCode != 13" full-width v-model="i.search"
                                    max-width="820" background-color="rgba(255, 255, 255, 0.7)"
                                    :rules="[rules.required,rules.min]" :placeholder="$t('search_losts')" outlined>
                                </v-text-field>
                                <v-btn min-width="160" width="160" height="50" class="ma-2 tl" :loading="loading"
                                    color="rgba(177, 189, 82, 1)" @click="lost_send()">
                                    <p class="alistSub tl">{{ $t('action_search') }}</p>
                                </v-btn>
                            </v-form>
                        </div>
                        <div class="lostResults">
                            <div :class="'list ' + $store.state.direction">
                                <div class="Titem" v-if="lostNoresTitem">
                                    <span class="tl" style="color:#FFF; padding-right: 30px;">{{ $t("name") }}</span>
                                    <span class="tl" style="color:#FFF; padding-right: 30px;">{{ $t("lost_doc") }}</span>
                                    <span class="tl d-none d-lg-flex d-xl-flex" style="color:#FFF; padding-right: 30px;">{{ $t("L_city") }}</span>
                                    <span class="tl d-none d-sm-flex" style="color:#FFF; padding-right: 30px;">{{ $t("lost_date") }}</span>
                                </div>
                                <div class="items">
                                    <div class="lostNores_item tl" v-if="lostNores">{{ $t("msg_no_result") }}</div>
                                    <div class="item" v-for="i in lostres" :key="i">
                                        <span class="tl" style="color:#FFF">{{i.name}}</span>
                                        <span class="tl" style="color:#FFF">{{i.type}}</span>
                                        <span class="tl d-none d-lg-flex d-xl-flex"  style="color:#FFF">{{i.city}}</span>
                                        <span class="tl d-none d-sm-flex"  style="color:#FFF">{{i.date}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="moreResults">
                                <v-btn href="https://alkafeel.net/lost/" target="_blank" dense class="ma-2 tl"
                                    color="rgba(177, 189, 82, 1)">
                                    <p class="alistSub tl">{{ $t('show_m_results') }}</p>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                    <div v-if="toggle_serForm=='masssage'" class="massage_con">
                        <a :href="'https://alkafeel.net/messages?lang=' + $i18n.locale" class="title">
                            <!-- <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span> -->
                            <span><img src="/assets/img/z-mohammed_dark.svg" /></span>
                            <p class="tl" style="color:#FFF">{{ $t("msg_message_to_shrine") }}</p>
                        </a>
                        <p class="tl"  style="color:#FFF">{{ $t("msg_message_to_shrine_text_index") }}</p>
                        <div>
                            <v-form class="massageForm" ref="form" v-model="valid">
                                <v-text-field @keyup.enter.native="message_send()" v-model="message_i.name"
                                    background-color="rgba(255, 255, 255, 0.7)" :rules="[rules.required]"
                                    :placeholder="$t('name')" outlined></v-text-field>
                                <v-text-field @keyup.enter.native="message_send()" v-model="message_i.email"
                                    background-color="rgba(255, 255, 255, 0.7)" :rules="[rules.email]"
                                    :placeholder="$t('msg_email_optional')" outlined></v-text-field>
                                <v-text-field @keyup.enter.native="message_send()" v-model="message_i.subject"
                                    background-color="rgba(255, 255, 255, 0.7)" :rules="[rules.required]"
                                    :placeholder="$t('contact_subject')" outlined></v-text-field>
                                <v-textarea v-model="message_i.message" background-color="rgba(255, 255, 255, 0.7)"
                                    :rules="[rules.required]" :placeholder="$t('msg_contents')" outlined></v-textarea>
                                <v-btn style="align-self: center;" min-width="200" height="56" class="ma-2 tl"
                                    :loading="loading" color="rgba(177, 189, 82, 1)" @click="message_send()">
                                    <p class="alistSub tl">{{ $t('action_send') }}</p>
                                </v-btn>
                            </v-form>
                        </div>
                    </div>
                    <div v-if="toggle_serForm=='restaurant'" class="zeara_con">
                        <a :href="'https://alkafeel.net/zyara?lang=' + $i18n.locale" class="title">
                            <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                            <p class="tl">حجز مضيف ابو الفضل العباس</p>
                        </a>
                        <p class="tl px-5" style="font-size: 15px;">يرحب مضيف العتبة بجميع الزائرين بكل حفاوة وود، ويقدم لهم الإقامة والخدمات المطلوبة خلال فترة إقامتهم في المدينة. يشتهر هذا المضيف بأجوائه الروحية والملؤها بالسكينة والطمأنينة، ما يساهم في خلق تجربة ساحرة للزائر.</p>
                        <div>
                            <h6 class="text-success">يرجى الأنتباه</h6>
                            <div class="d-flex wa-res px-10">
                                <ul>
                                <li>
                                    ١- يقدم مضيف الزائرين وجبة غداء فقط على مدار أيام السنة للزائرين الكرام من داخل العراق وخارجه ويبدأ باستقبالهم من الساعة ١١:٠٠ صباحاً الى ١:٠٠ ظهرا.
                                </li>
                                <li>
                                    ٢- يقدم مضيف الزائرين وجبة إفطار خلال شهر رمضان المبارك للصائمين الكرام من داخل العراق وخارجه ويبدأ بإستقبالهم من الساعة ٥:٣٠ الى ٧:٠٠ مساءً.
                                </li>
                                <li>
                                    ١- يقدم مضيف الزائرين وجبة غداء فقط على مدار أيام السنة للزائرين الكرام من داخل العراق وخارجه ويبدأ باستقبالهم من الساعة ١١:٠٠ صباحاً الى ١:٠٠ ظهرا.
                                </li>
                                <li>
                                    ١- يقدم مضيف الزائرين وجبة غداء فقط على مدار أيام السنة للزائرين الكرام من داخل العراق وخارجه ويبدأ باستقبالهم من الساعة ١١:٠٠ صباحاً الى ١:٠٠ ظهرا.
                                </li>
                                <li>
                                    ٢- يقدم مضيف الزائرين وجبة إفطار خلال شهر رمضان المبارك للصائمين الكرام من داخل العراق وخارجه ويبدأ بإستقبالهم من الساعة ٥:٣٠ الى ٧:٠٠ مساءً.
                                </li>
                            </ul>
                            
                            </div>
                            <div class="d-flex justify-content-center">
                                <v-btn style="align-self: center;" min-width="200" height="56" class="ma-2 tl"
                                    :loading="loading" color="rgba(177, 189, 82, 1)">
                                    <p class="alistSub tl">{{ $t('action_send') }}</p>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="serIcons">
                    <v-layout row wrap justify-center>
                        <v-flex sm3 xs4 md3 lg2 class="d-flex d-sm-none">
                            <a @click="desvideoplayer('zeara','zeara_insted','servicesID')">
                                <v-btn value="zeara_insted" outlined min-height="164" class="btn2">
                                    <div class="img zeara_insted"></div>
                                    <p class="tl">{{ $t("index_zyara") }}</p>
                                </v-btn>
                            </a>
                        </v-flex>
                        <v-flex sm3 xs4 md3 lg2 class="d-flex d-sm-none">
                            <a @click="desvideoplayer('masssages','masssage','servicesID')">
                                <v-btn value="masssage" outlined min-height="164" class="btn2">
                                    <div class="img message"></div>
                                    <p class="tl">{{ $t("msg_message_to_shrine") }}</p>
                                </v-btn>
                            </a>
                        </v-flex>
                        <v-flex sm3 xs4 md3 lg2 class="d-flex d-sm-none">
                            <a @click="desvideoplayer('questions','question','servicesID')">
                                <v-btn value="question" outlined min-height="164" class="btn2">
                                    <div class="img question"></div>
                                    <p class="tl">{{ $t("relig_questions") }}</p>
                                </v-btn>
                            </a>
                        </v-flex>
                        <v-flex sm3 xs4 md3 lg2 class="d-flex d-sm-none" v-if="$i18n.locale=='ar'">
                            <a @click="iniLostCon('lost','servicesID')">
                                <v-btn value="lost" outlined min-height="164" class="btn2">
                                    <div class="img lost"></div>
                                    <p class="tl">{{ $t("index_lost") }}</p>
                                </v-btn>
                            </a>
                        </v-flex>
                        <!-- <v-btn-toggle v-model="toggle_serForm"> -->
                        <!-- <v-flex sm3 xs4 md3 lg2 class="d-none d-sm-flex">
                        <a @click="inivideoplayer('lives')" ref="abc">
                            <v-btn value="live" outlined min-height="164" class="btn2">
                                <div class="img live"></div>
                                <p class="tl">{{ $t("index_live") }}</p>
                            </v-btn>
                        </a>
                        </v-flex> -->
                                <!-- </v-btn-toggle> -->
                                <!-- <v-flex sm3 xs4 md3 lg2 class="d-flex d-sm-none">
                            <a @click="inivideoplayer('lives', 'servicesID')">
                                <v-btn value="live" outlined min-height="164" class="btn2">
                                    <div class="img live"></div>
                                    <p class="tl">{{ $t("index_live") }}</p>
                                </v-btn>
                            </a>
                        </v-flex> -->
                        <v-flex sm3 xs4 md3 lg2 class="d-flex d-sm-none" v-if="$i18n.locale!='ar'">
                            <a :href="'https://alkafeel.net/radio/?lang='+$i18n.locale" target="_blank">
                                <v-btn outlined min-height="164" class="btn2">
                                    <div class="img radio"></div>
                                    <p class="tl">{{ $t("radio") }}</p>
                                </v-btn>
                            </a>
                        </v-flex>
                        <v-flex sm3 xs4 md3 lg2>
                            <a :href="'https://alkafeel.net/panorama/?lang='+$i18n.locale" target="_blank">
                                <v-btn outlined min-height="164" class="btn2">
                                    <div class="img virtual_reality"></div>
                                    <p class="tl">{{ $t("virtual_reality") }}</p>
                                </v-btn>
                            </a>
                        </v-flex>
                        <v-flex sm3 xs4 md3 lg2 class="">
                            <router-link :to="'/media/?lang='+$i18n.locale">
                                <v-btn outlined min-height="164" class="btn2">
                                    <div class="img index_media"></div>
                                    <p class="tl">{{ $t("index_videos") }}</p>
                                </v-btn>
                            </router-link>
                        </v-flex>
                        <v-flex sm3 xs4 md3 lg2 class="d-none d-sm-flex">
                            <a :href="'https://alkafeel.net/radio/?lang='+$i18n.locale" target="_blank">
                                <v-btn outlined min-height="164" class="btn2 border-right">
                                    <div class="img radio"></div>
                                    <p class="tl">{{ $t("radio") }}</p>
                                </v-btn>
                            </a>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
    name: "VideoPlayer",
    data() {
        return {
            toggle_serForm: 'zeara_insted',
            toggle_src: 'src1',
            zeara_i: {
                name: '',
                email: '',
                ab: '',
                save: 'save'
            },
            questions_i: {
                name: '',
                email: '',
                quest: '',
                phone: ''
            },
            message_i: {
                name: '',
                email: '',
                subject: '',
                message: ''
            },
            i: {
                search: '',
            },
            serConStyle: "zeara",
            lostNores: false,
            lostNoresTitem: true,
            liveres: [],
            zyarares: [],
            lostres: [],
            loader: null,
            loading: false,
            player: null,
            videoOptions: {
                autoplay: true,
                controls: true,
                fill: true,
                //fluid: false,
                sources: [{
                    src: "",
                    type: "application/x-mpegURL"
                }],
            },
            swiperOptions3: {
                slidesPerView: 'auto',
                spaceBetween: 15,
                freeMode: true,
                //loop:true,
                keyboard: true,
                breakpoints: {
                    //     320: {
                    //         slidesPerView: 2,
                    //         spaceBetween: 10
                    //     },
                    //     400: {
                    //         slidesPerView: 2,
                    //         spaceBetween: 35
                    //     },
                    //     600: {
                    //         slidesPerView: 2,
                    //         spaceBetween: 20
                    //     }
                }
            },
            valid: false,
            wait: 10, // in minutes
            rules: {
                required: value => !!value || this.$t("required_field"),
                min: (v) => v.length >= 3 || this.$t("three_letters"),
                email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t(
                    "alert_wrong_email"),
                // email: value => {
                //     if (value.length > 0) {
                //         const pattern =
                //             /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                //         return pattern.test(value) || this.$t("alert_wrong_email");
                //     }
                // },
            },
        }
    },
    watch: {
        loader() {
            const l = this.loader
            this[l] = !this[l]
            setTimeout(() => (this[l] = false), 3000)
            this.loader = null
        },
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper3.$swiper
        }
    },
    mounted() {

    },
    created() {
        this.initialize();
    },
    updated() {
        if (location.hash != '') {
            document.getElementById(location.hash.replace('#', '')).scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest"
            });
        }
    },
    methods: {
        initialize() {
            this.$axios.get("zyara/GetDetails", {})
                .then(res => {
                    this.zyarares = res.data.Data;
                    //console.log(res.data);
                })
                .catch(() => {});
        },
        zeara_send() {
            if (this.$refs.form.validate()) {
                var formdata = new FormData();
                //   formdata.append("name",  this.zeara_i.name);
                formdata.append("ab", this.zeara_i.ab);
                formdata.append("save", "save");
                formdata.append("email", this.zeara_i.email);
                this.loader = 'loading';
                this.$axios({
                    method: 'post',
                    url: 'https://alkafeel.net/zyara/Avdasdw/1352897989/post',
                    data: formdata,
                }).then(
                    this.zeara_i = {},
                    Swal.fire({

                        position: 'center',
                        icon: 'success',
                        title: this.$t("zeara_result"),
                        showConfirmButton: false,
                        timer: 2000

                    })
                    //console.log(res.data);
                ).catch(() => {});
            }
        },
        question_send() {
            if (this.$refs.form.validate()) {
                this.loader = 'loading';
                this.$axios.post('religious/sendQuest', {
                    name: this.questions_i.name,
                    phone: this.questions_i.phone,
                    email: this.questions_i.email,
                    quest: this.questions_i.quest,
                }).then(
                    Swal.fire({


                        position: 'center',
                        icon: 'success',
                        title: this.$t("zeara_result"),
                        showConfirmButton: false,
                        timer: 2000

                    }),
                    this.questions_i = {}
                ).catch(() => {});
            }
        },
        message_send() {
            if (this.$refs.form.validate()) {
                this.loader = 'loading';
                var query;
                if (this.message_i.email == '') {
                    query = {
                        name: this.message_i.name,
                        subject: this.message_i.subject,
                        message: this.message_i.message,
                    }
                } else {
                    query = this.message_i
                }




                if (this.checkCookie('message', 'DMI')) {
                    Swal.fire({
                        title: this.$t("messege_wait")+' '+this.wait+' '+this.$t("time_mins"),
                        text: "",
                        icon: "warning",
                        timer: 3000,
                        confirmButtonColor: 'red',
                    });
                } else {
                    this.$axios.post('alkafeelMessage/store', query).then((res) => {
                        if (res.status == 200) {
                            Swal.fire({
                                title:  '<strong>'+this.$t('msg_message_sent')+'</strong>',
                                html: this.$t('msg_use_code_to') +  '  &nbsp <h2>' + res.data.code+'</h2>',
                                icon: "success",
                                confirmButtonColor: '#B1BD52', 
                                confirmButtonText: this.$t('msg_copy_code'),
                                preConfirm: () => {
                            
                                const el = document.createElement('textarea');
                                el.value = res.data.code;
                                document.body.appendChild(el);
                                el.select();
                                document.execCommand('copy');
                                document.body.removeChild(el);
                                }
                            });
                            this.message_i = {};

                            this.setCookie('message', 'DMI');
                        }
                        else {
                            Swal.fire({
                                title: this.$t("alert_send_error"),
                                text: "",
                                icon: "error",
                                timer: 3000,
                                confirmButtonColor: 'red',
                            });
                        }
                    }
                    ).catch(() => {
                        Swal.fire({
                            title: this.$t("alert_send_error"),
                            text: "",
                            icon: "error",
                            timer: 3000,
                            confirmButtonColor: 'red',
                        });
                    });
                }





            }
        },
        setCookie(cname, cvalue) {
            const d = new Date();
            d.setTime(d.getTime() + (this.wait*60*1000));
            let expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
        getCookie(cname) {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        checkCookie(cname, id) {
            let username = this.getCookie(cname);
            if (username != "") {
                if (id === username) {
                    return true
                } else {
                    return false
                }
            }
        },
        pre_lost_send() {
            setTimeout(() => this.lost_send(), 1000);
        },
        lost_send() {
            if (this.$refs.form.validate()) {
                this.loader = 'loading';
                this.$axios.get('losts/search/' + this.i.search, {}).then(res => {
                    this.lostres = res.data;
                    if (res.data == "") {
                        this.lostNores = true;
                        this.lostNoresTitem = false;
                    } else {
                        this.lostNores = false;
                        this.lostNoresTitem = true;
                    }
                }).catch(() => {});
            }
        },
        live_src() {
            this.$axios.get("live/all", {})
                .then(res => {
                    this.liveres = res.data;
                    this.videoOptions.sources[0].src = res.data[0]['src'];
                    this.player.poster('https://alkafeel.net/live/images/' + res.data[0]['pic']);
                })
                .catch(() => {});
        },
        play_player() {
            this.player = videojs(this.$refs.videoPlayer, this.videoOptions, function onPlayerReady() {})
        },
        inivideoplayer(x, d = '') {
            if (d != '') {
                this.jumptoID(d);
            }
            this.toggle_serForm = 'live';
            this.serConStyle = x;
            this.live_src();
            setTimeout(() => this.play_player(), 500);
            setTimeout(() => document.getElementById('element1').click(), 1000);
        },
        desvideoplayer(x, i, d = '') {
            if (d != '') {
                this.jumptoID(d);
            }
            this.toggle_serForm = i;
            this.serConStyle = x;
            if (this.player) {
                this.player.dispose();
                this.player = null;
            }
        },
        iniLostCon(x, d = '') {
            if (d != '') {
                this.jumptoID(d);
            }
            this.toggle_serForm = 'lost';
            this.serConStyle = x;
            if (this.player) {
                this.player.dispose()
            }
            this.$axios.get("losts/searchlimit", {})
                .then(res => {
                    this.lostres = res.data;
                    if (res.data == "") {
                        this.lostNores = true;
                        this.lostNoresTitem = false;
                    } else {
                        this.lostNores = false;
                        this.lostNoresTitem = true;
                    }
                })
                .catch(() => {});
        },
        changeVIDsrc(x, i) {
            this.player.poster('https://alkafeel.net/live/images/' + i);
            this.player.src({
                type: "application/x-mpegURL",
                src: x
            });
            this.player.play();
        },
        jumptoID(d) {
            document.getElementById(d).scrollIntoView();
        },
    },
}
</script>